
.awsPhoto {
  position: relative;
}

.awsPhoto img {
    width: 50%;
}

input[type="file"] {
  width: 154px !important;
  height: 46px !important;
  position: relative !important;
  top: -50px !important;
}
