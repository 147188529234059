.container div {
  margin: 10px;
}

.MuiFormControl-root {
    margin: 10px !important;
}


.MuiFormGroup-root {
    margin: 10px !important;
}
